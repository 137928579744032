














































































































import { Vue, Component, Watch } from 'vue-class-decorator'
import { UserModule, AccessRightsModule, GlobalModule } from '@/store'
import { Payload } from 'vue/interfaces'

@Component({
  components: {
    RenderMenu: () => import('@/components/menu/RenderMenu.vue')
  }
})
export default class Main extends Vue {
  public drawer: boolean
  public user: object

  // Data
  constructor() {
    super()
    this.drawer = false
    this.user = {
      image: {
        // eslint-disable-next-line
        web_url: null
      }
    }
  }

  // Store init
  @GlobalModule.Getter('watchSignalRenderMenu') signalRenderMenu!: boolean
  @UserModule.Action('logout') userLogout!: () => Promise<void>
  @UserModule.Action('checkUser') loadUserData!: () => Promise<void>
  @UserModule.Getter('userInfo') userInfo!: { [propName: string]: any }
  @AccessRightsModule.Action('getMenuListByPermission') getMenuListByPermission!: (router: Payload) => Promise<void>

  // Watch
  @Watch('signalRenderMenu') getNewMenuList() {
    this.loadMenu()
  }

  // Methods
  exit() {
    this.userLogout()
      .then(() => {
        setTimeout(() => {
          this.$router.push('/login')
        }, 1000)
      })
  }

  setUserController() {
    if (this.userInfo.image_id === 0) {
      this.user = {
        ...this.userInfo,
        image: {
          // eslint-disable-next-line
          web_url: null
        }
      }
    } else {
      this.user = this.userInfo
    }
  }

  get containerStyles() {
    return this.$route.name === 'home' ? 'max-width: 100%;' : ''
  }

  async loadUser() {
    if (!this.userInfo?.user_id) {
      this.loadUserData()
        .then(() => {
          this.setUserController()
        })
        .catch((error: any) => {
          this.$noty('error', error.message, 7000).show()
        })
    } else {
      this.setUserController()
    }
  }

  async loadMenu() {
    this.getMenuListByPermission(this.$router.options as Payload)
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  async created() {
    await this.loadMenu()
    this.loadUser()
  }
}
